<template>
  <b-overlay
    :show="overlayShow"
  >
    <b-tabs v-if="!overlayShow">
      <b-tab
        :disabled="forOpenActs.length === 0"
        title="Открыть"
        lazy
      >
        <open-acts
          :items="forOpenActs"
          :group="group"
          :is-day="isDay"
          @opened="onOpenedActsHandler"
        />
      </b-tab>
      <b-tab
        :disabled="openedActs.length === 0"
        title="Печатать"
        lazy
      >
        <print-acts
          :items="openedActs"
        />
      </b-tab>
      <b-tab
        :disabled="openedActs.length === 0"
        title="Закрыть"
        lazy
      >
        CLOSE
      </b-tab>
    </b-tabs>
    <div v-else>
      <h3 class="text-center pt-1 pb-1">Загрузка</h3>
    </div>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import OpenActs from '@/views/cars/components/car-plan-to-acts/OpenActs.vue'
import PrintActs from '@/views/cars/components/car-plan-to-acts/PrintActs.vue'
import boolean from 'vue-good-table/src/components/types/boolean'

export default {
  components: {
    PrintActs,
    OpenActs,
    BOverlay,
    BTabs,
    BTab,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    group: {
      type: Object,
      required: true,
    },
    isDay: {
      type: Boolean,
      default() {
        return true
      },
    },
  },
  data() {
    return {
      step: 1,
      overlayShow: false,
      plan: [],
    }
  },
  computed: {
    forOpenActs() {
      return this.plan.filter(item => item.act === undefined && item.plan.driver !== null)
    },
    openedActs() {
      return this.plan.filter(item => item.act && item.act.status === 'opened')
    },
  },
  mounted() {
    this.fetchExistsPlanActs()
  },
  methods: {
    fetchExistsPlanActs() {
      this.overlayShow = true
      this.$axios.get('cars/acts', {
        params: {
          filter: {
            plan_id__in: this.items.map(item => item.plan.id).join(','),
          },
        },
      }).then(response => {
        this.plan = this.items.map(item => ({
          car: item.car,
          plan: item.plan,
          act: response.data.data.filter(act => act.plan_id === item.plan.id)?.pop(),
        }))
      }).finally(() => {
        this.overlayShow = false
      })
    },
    onOpenedActsHandler(acts) {
      this.plan = this.items.map(item => ({
        car: item.car,
        plan: item.plan,
        act: acts.filter(act => act.plan_id === item.plan.id)?.pop(),
      }))
    },
  },
}
</script>
