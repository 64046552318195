<template>
  <div>
    <template v-if="plan && driver">
      <span>{{ driver | displayDriver }}</span>
      <div class="d-flex flex-row justify-content-between mt-1">
        <div class="small">{{ driver.license_number }}</div>
        <div class="small">{{ driver.balance }}</div>
      </div>
    </template>
    <template v-else-if="plan && plan.driver_id === null">
      <h4 class="text-center">Ремонт</h4>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    plan: {
      type: Object,
      default() {
        return null
      },
    },
  },
  computed: {
    driver() {
      return this.plan?.driver
    },
  },
}
</script>
