<template>
  <div>
    <div class="row">
      <div class="col-2 d-flex align-content-center flex-wrap">
        <b-form-checkbox
          @input="onAllSelectUpdate"
        />
      </div>
      <div class="col d-flex align-content-center flex-wrap font-weight-bolder">
        Автомобиль
      </div>
      <div class="col d-flex align-content-center flex-wrap font-weight-bolder">
        Водитель
      </div>
    </div>
    <hr class="mb-2" />
    <div
      v-for="(item, index) in formData"
      :key="item.plan_id"
      class="row mb-1"
    >
      <div class="col-1 d-flex align-content-center flex-wrap">
        <b-form-checkbox
          v-model="item.selected"
        />
      </div>
      <div class="col">
        <div>{{ items[index].car.mark }} {{ items[index].car.model }}</div>
        <div>{{ items[index].car.number }}</div>
      </div>
      <div class="col d-flex align-content-center flex-wrap">
        {{ items[index].plan.driver.last_name }} {{ items[index].plan.driver.first_name }}
        {{ items[index].plan.driver.middle_name }}
      </div>
    </div>
    <hr class=" mt-2" />
    <div class="d-flex justify-content-end flex-grow-1">
      <b-button
        variant="primary"
        class="ml-1"
        :disabled="!saveButtonEnabled"
        @click="onPrintButtonHandler"
      >
        Распечатать
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BFormCheckbox,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BFormCheckbox,
    BButton,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      formData: [],
    }
  },
  computed: {
    saveButtonEnabled() {
      return this.formData.filter(item => item.selected).length > 0
    },
  },
  watch: {
    items() {
      this.formDataSetter()
    },
  },
  mounted() {
    this.formDataSetter()
  },
  methods: {
    formDataSetter() {
      this.formData = this.items.map(item => ({
        id: item.act.id,
        selected: false,
      }))
    },
    onAllSelectUpdate(value) {
      this.formData = this.formData.map(item => ({
        ...item,
        selected: value,
      }))
    },
    onPrintButtonHandler() {
      const id = this.formData.filter(item => item.selected).map(item => item.id)
      if (id.length <= 0) {
        return
      }
      this.$axios.post('print/car-act', { id }, { responseType: 'blob' })
    },
  },
}
</script>
