<template>
  <div>
    <div class="row">
      <div class="col-1 d-flex align-content-center flex-wrap">
        <b-form-checkbox
          @input="onAllSelectUpdate"
        />
      </div>
      <div class="col-4 d-flex align-content-center flex-wrap font-weight-bolder">
        Автомобиль
      </div>
      <div class="col d-flex align-content-center flex-wrap font-weight-bolder">
        Водитель
      </div>
      <div class="col-2">
        <b-form-timepicker
          v-model="globalTime"
          @input="onGlobalTimeChangeHandler"
        />
      </div>
    </div>
    <hr class="mb-2" />
    <div
      v-for="(item, index) in formData"
      :key="item.act.plan_id"
      class="row mb-1"
    >
      <div class="col-1 d-flex align-content-center flex-wrap">
        <b-form-checkbox
          v-model="item.selected"
        />
      </div>
      <div
        class="col-4"
        @click="item.collapsed = !item.collapsed"
      >
        <div>{{ items[index].car.mark }} {{ items[index].car.model }}</div>
        <div>{{ items[index].car.number }}</div>
      </div>
      <div
        class="col d-flex align-content-center flex-wrap"
        @click="item.collapsed = !item.collapsed"
      >
        {{ items[index].plan.driver.last_name }} {{ items[index].plan.driver.first_name }}
        {{ items[index].plan.driver.middle_name }}
      </div>
      <div
        class="col-2"
      >
        <b-form-timepicker
          v-model="item.act.time_start"
        />
      </div>
      <b-collapse
        v-model="item.collapsed"
        class="col-12"
      >
        <car-act-form
          :value="item.act"
          action="open"
          @input="(act) => item.act = act"
        />
      </b-collapse>
    </div>
    <hr class=" mt-2" />
    <div class="d-flex justify-content-end flex-grow-1">
      <b-button
        variant="primary"
        class="ml-1"
        :disabled="!saveButtonEnabled"
        @click="onSaveButtonHandler"
      >Сохранить
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BButton, BFormCheckbox, BFormTimepicker, BCollapse,
} from 'bootstrap-vue'
import moment from 'moment'
import CarActForm from '../CarActForm.vue'

export default {
  components: {
    BFormTimepicker,
    BFormCheckbox,
    BButton,
    BCollapse,
    CarActForm,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    group: {
      type: Object,
      required: true,
    },
    isDay: {
      type: Boolean,
      default() {
        return true
      },
    },
  },
  data() {
    return {
      formData: [],
      globalTime: null,
    }
  },
  computed: {
    defaultTime() {
      return this.isDay ? this.group.time_start : this.group.time_end
    },
    saveButtonEnabled() {
      return this.formData.filter(item => item.selected).length > 0
    },
  },
  watch: {
    items() {
      this.formDataSetter()
    },
  },
  mounted() {
    this.globalTime = this.defaultTime
    this.formDataSetter()
  },
  methods: {
    formDataSetter() {
      this.formData = this.items.map(item => ({
        act: {
          car_id: item.car.id,
          car_group_id: item.car.group_id,
          plan_id: item.plan.id,
          driver_id: item.plan.driver.id,
          mileage_start: item.car.mileage,
          date: item.plan.date,
          time_start: this.defaultTime,
          equipped_start: item.car.equipped,
        },
        collapsed: false,
        selected: false,
      }))
    },
    onGlobalTimeChangeHandler() {
      this.formData = this.formData.map(item => ({
        ...item,
        act: {
          ...item.act,
          time_start: this.globalTime,
        },
      }))
    },
    onAllSelectUpdate(value) {
      this.formData = this.formData.map(item => ({
        ...item,
        selected: value,
      }))
    },
    onSaveButtonHandler() {
      const data = this.formData.filter(item => item.selected).map(item => {
        const date = moment(`${item.act.date} ${item.act.time_start}`)
        return {
          ...item.act,
          status: 'opened',
          date_start: date.format('YYYY-MM-DD HH:mm'),
          date_end: date.add(12, 'hours').format('YYYY-MM-DD HH:mm'),
        }
      })
      this.$axios.post('cars/acts-bulk', { data }).then(response => {
        this.$emit('opened', response.data.data)
      })
    },
  },
}
</script>
